
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['button']

  /* ----- ACTION EVENTS ----- */

  onClickButton(event){
    if (this.buttonTarget.dataset.confirm == undefined) {
      this.buttonTarget.classList.add('disabled')
    }
  }
}
