import { Controller } from 'stimulus'
import { get } from '../utils/ajax'
export default class extends Controller {
  /* ----- LIFECYCLE CALLBACKS ----- */

  /* ----- LIFECYCLE CALLBACKS ----- */

  connect() {
    $('.form-control.select2').on('select2:select', function () {
        let event = new Event('change')
        this.dispatchEvent(event);
      });
      $('.form-control.select2').on('select2:unselect', function () {
        let event = new Event('change')
        this.dispatchEvent(event);
      });
      $('.form-control.select2').on('select2:clear', function () {
        let event = new Event('change')
        this.dispatchEvent(event);
      });

  }

  /* ----- ACTION EVENTS ----- */

  onSetModel(event) {
    $('#section_name').val(null)
    $('#section_type_model').attr('checked', true)
    $('#section_type_name').attr('checked', false)
  }
  onSetManual(event) {
    $('#section_template_id').val(null).trigger("change")
    $('#section_type_name').attr('checked', true)
    $('#section_type_model').attr('checked', false)
  }

  checkButton(event){
    if (document.querySelector('#section_template_id').value == ''){
      document.querySelector('#button-reload').classList.add('disabled')
    }
    else {
      document.querySelector('#button-reload').classList.remove('disabled')
    }
  }

  async onReloadTemplate(event) {
    if (!event.currentTarget.classList.contains('disabled')){
      if(confirm("Êtes-vous sûr(e) de vouloir supprimer le texte et le nom personnalisé ?")) {
        var target = event.currentTarget
        var url = target.getAttribute('data-url') + "?template_id=" + target.closest('.parent').querySelector('#section_template_id').value
        const response = await get(url)
        document.querySelector('#section_name').value = response.name
        editor.setData(response.description ? response.description : "")
      }
    }
    
  }
}
