import { Controller } from 'stimulus'
import Rails from "@rails/ujs"

export default class extends Controller {
  /* ----- LIFECYCLE CALLBACKS ----- */

  static targets = ['company', 'contact', 'type']

  /* ----- LIFECYCLE CALLBACKS ----- */

  connect() {
    $('.form-control.select2').on('select2:select', function () {
      let event = new Event('change')
      this.dispatchEvent(event);
    });
    $('.form-control.select2').on('select2:unselect', function () {
      let event = new Event('change')
      this.dispatchEvent(event);
    });
    $('.form-control.select2').on('select2:clear', function () {
      let event = new Event('change')
      this.dispatchEvent(event);
    });
    if (this.typeTarget.value == "provider" || this.typeTarget.value == "subcontractor") {
      $('.parent_company').show();
    } else {
      $('.parent_company input').val('');
      $('.parent_company').hide();
    }
  }

  /* ----- ACTION EVENTS ----- */

  changeCompany(event) {
    $('#inspection_report_contact_id').val(null).trigger("change");
    $('#inspection_report_contact_id').select2({
      ajax: {
        url:  event.target.getAttribute("data-url") + '?entity_id=' + this.companyTarget.value,
        dataType: 'json',
        delay: 250,
        data: function (params) {
          return {
            q: params.term // search term
          };
        },
        processResults: function (data) {
          // parse the results into the format expected by Select2.
          // since we are using custom formatting functions we do not need to
          // alter the remote JSON data
          return {
            results: $.map(data, function(obj) {
              return { id: obj.id, text: obj.text };
            })
          };
        },
        cache: true
        // Additional AJAX parameters go here; see the end of this chapter for the full code of this example
      }
    });
  }
  parentCompanyVisible(event) {
    if (event.target.value == "provider" || event.target.value == "subcontractor") {
      $('.parent_company').show();
      if(event.target.value == "subcontractor"){
        $('.parent_company label').text('Sous-traitant de')
      }
      if(event.target.value == "provider"){
        $('.parent_company label').text('Prestataire de');
      }
    } else {
      $('.parent_company input').val('');
      $('.parent_company').hide();
    }
  }
}
