import { Controller } from 'stimulus'
export default class extends Controller {
  /* ----- LIFECYCLE CALLBACKS ----- */

  static targets = ['input']

  /* ----- LIFECYCLE CALLBACKS ----- */

  connect() {
    this.inputTargets.forEach(input => {
      if (input.checked == false) {
        input.closest('.coordination-section-container').querySelectorAll('.coordination-section-container').forEach(function (item) {
          item.querySelectorAll('input').forEach(function (i) {
            i.setAttribute('disabled', true)
            i.checked = false
          })
        })
      }
      
    })
  }

  /* ----- ACTION EVENTS ----- */

  onChangeCheck(event) {
    var input = event.target
    if (input.checked == false) {
      input.closest('.coordination-section-container').querySelectorAll('.coordination-section-container').forEach(function (item) {
        console.log(item)
        item.querySelectorAll('input').forEach(function (i) {
          i.disabled = true
          i.checked = false
        })
      })
    }
    else {
      input.closest('.coordination-section-container').querySelectorAll('.coordination-section-container').forEach(function (item) {
        item.querySelectorAll('input').forEach(function (i) {
          i.disabled = false
          i.checked = true
        })
      })
    }
  }
}
