import Rails from '@rails/ujs'

const ajax = async config => {
  return new Promise((resolve, reject) => {
    Rails.ajax({
      ...config,
      success: data => {
        resolve(data)
      },
    })
  })
}

const get = async url => {
  return await ajax({ url, type: 'GET', dataType: 'script' })
}

const post = async (url, data = {}) => {
  return await ajax({ url, data, type: 'POST', dataType: 'script' })
}

const update = post

const put = async (url, data = {}) => {
  return await ajax({ url, data, type: 'PUT', dataType: 'script' })
}

const remove = async (url, data = {}) => {
  return await ajax({ url, data, type: 'DELETE', dataType: 'script' })
}

export { ajax, get, post, update, put, remove }
