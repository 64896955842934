import { Controller } from 'stimulus'
import Rails from "@rails/ujs"

export default class extends Controller {
  /* ----- LIFECYCLE CALLBACKS ----- */

  static targets = ['form']

  /* ----- LIFECYCLE CALLBACKS ----- */

  connect() {
    $('.form-control.grouped_select2, .form-control.select2').on('select2:select', function () {
      let event = new Event('change')
      this.dispatchEvent(event);
    });
    $('.form-control.grouped_select2, .form-control.select2').on('select2:unselect', function () {
      let event = new Event('change')
      this.dispatchEvent(event);
    });
    $('.form-control.grouped_select2, .form-control.select2').on('select2:clear', function () {
      let event = new Event('change')
      this.dispatchEvent(event);
    });
  }

  /* ----- ACTION EVENTS ----- */

  onChangeSelect(event) {
    event.preventDefault()
    var form = event.currentTarget.closest('form')
    Rails.fire(form, 'submit')
  }
}
