import { Controller } from 'stimulus'

export default class extends Controller {
  /* ----- LIFECYCLE CALLBACKS ----- */

  /* ----- ACTION EVENTS ----- */

  onToggleRowClick(event) {
    event.preventDefault()
    this.toggleRow(event.currentTarget)
  }

  /* ----- FUNCTIONS ----- */

  toggleRow(target) {
    const row = target.closest('tr').nextElementSibling

    if (row.classList.contains('hide')) {
      target.innerHTML = target.dataset.hideText
    } else {
      target.innerHTML = target.dataset.showText
    }

    row.classList.toggle('hide')
  }

  /* ----- GETTERS / SETTERS ----- */
}
