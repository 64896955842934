import SignaturePad from 'signature_pad'
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['canvas', 'imgContainer', 'input']
  /* ----- LIFECYCLE CALLBACKS ----- */

  connect () {
    this.signaturePad = new SignaturePad(this.canvasTarget);
  }

  onEraserClick(event) {
    event.preventDefault()
    this.signaturePad.clear();
  }

  onSaveClick(event) {
    event.preventDefault()
    if (this.signaturePad.isEmpty()) {
      alert("Please provide a signature first.");
    } else {
      var dataURL = this.signaturePad.toDataURL();
      this.inputTarget.value = dataURL;
      this.imgContainerTarget.innerHTML = '<p>Aperçu :</p><img src="' + dataURL + '" alt="Aperçu" title="Aperçu">';
    }
  }
}
