import { Controller } from 'stimulus'
export default class extends Controller {
  /* ----- LIFECYCLE CALLBACKS ----- */

  static targets = ['form', 'autoDescription', 'description']

  /* ----- LIFECYCLE CALLBACKS ----- */

  connect() {
    $('.form-control.select2').on('select2:select', function () {
        let event = new Event('change')
        this.dispatchEvent(event);
      });
      $('.form-control.select2').on('select2:unselect', function () {
        let event = new Event('change')
        this.dispatchEvent(event);
      });
      $('.form-control.select2').on('select2:clear', function () {
        let event = new Event('change')
        this.dispatchEvent(event);
      });
      editor.model.document.on('change', (evt, type , data) => {
        this.checkHeightBody()
        if (type.type == 'default'){
          $('#section_auto_description').val(null).trigger("change");
          $('#description_type_selector_text').prop('checked', true);
          $('#description_type_selector_auto').prop('checked', false);
        }
      });
      this.checkHeightBody()     

  }

  /* ----- ACTION EVENTS ----- */
  onSetAuto(event) {
    if(!(this.descriptionTarget.value.length || editor.getData()) || confirm("Êtes-vous sûr(e) de vouloir supprimer le texte personnalisé ?")) {
      this.descriptionTarget.value = null
      editor.setData('')
      $('#description_type_selector_auto').prop('checked', true);
    } else {
      $('#description_type_selector_auto').prop('checked', false);
      $('#description_type_selector_text').prop('checked', true);
    }
  }
  onSetManual(event) {
    $('#section_auto_description').val(null).trigger("change");
    $('#description_type_selector_text').prop('checked', true);
    $('#description_type_selector_auto').prop('checked', false);
  }

  checkHeightBody() {
    if (document.body.scrollHeight >= 1200) {

      $(".sticky-bottom" ).css( "z-index", 10300);
    }
    else {
      $(".sticky-bottom" ).css( "z-index", 1030);
    }  
  }
}
